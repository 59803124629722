import { useYearData, useSubjectData } from 'hooks/testSelect'
import { FC, useEffect, useRef, useState } from 'react'
import Header from './Header'
import { Outlet, useNavigate } from 'react-router'
import useTestContext from 'contexts/TestContext/hook'
import useTitle from 'hooks/useTitle'
import useAvailableShortsData from 'hooks/testSelect/useAvailableShortsData'
import useAuthContext from 'contexts/Auth/useAuthContext'
import { Link } from 'react-router-dom'

const TestPicker: FC = () => {
  const {user} = useAuthContext()
  const [yearsOffset, setYearsOffset] = useState(0)
  const [testOffset, setTestOffset] = useState(0)
  const availableShorts = useAvailableShortsData()
  const { subject, subjects } = useSubjectData()
  const { years, year } = useYearData()
  const navigate = useNavigate()
  const { start, data, answers } = useTestContext()
  const wrapper = useRef<HTMLDivElement>(null)

  const [lastTestData, setLastTestData] = useState(data)

  useEffect(() => {
    if (!data) return
    setLastTestData(data)
  }, [data])

  const subjectHidden = !!subject
  const yearHidden = !subjectHidden || year
  const testHidden = !year

  const twoShown = [yearHidden, subjectHidden, testHidden].filter((e) => !e).length === 2
  const nothingShown = yearHidden && subjectHidden && testHidden

  const toSubject = (subject: string) => {
    navigate(`/tests/svk/${subject}`)
  }

  const toYear = (year: number) => {
    navigate(`/tests/svk/${subject}/${year}`)
  }

  const title = subject ? (year ? `${subject && subjects?.[subject]} ${year}` : subjects?.[subject]) : ""

  const shortsAvailableForSubject = !!availableShorts?.[subject || ""]

  useTitle(title)

  return (
    <>
      <div className="absolute inset-0 z-[10] pointer-events-none">
        <Outlet />
      </div>
      <div ref={wrapper} className="flex flex-col pt-[calc(93px+1.5rem)] mobile:pt-[calc(10rem)] items-center h-0 overflow-visible animate-fade-in-delay">
        <Header pick={subject && subjects?.[subject]} hideDubky={Boolean(data)} />
        <div
          ref={(e) => {
            if (!e) return
            setYearsOffset(e.clientHeight)
            if (!subjectHidden && !twoShown) {
              e.classList.add("in")
              e.classList.remove("out")
            } else {
              !nothingShown && e.classList.contains("in") && e.classList.remove("in")
              !e.classList.contains("out") && e.classList.add("out")
            }
          }}
          className={['flex flex-col gap-4 mt-8 items-center pb-8 animate-subj', subject && 'pointer-events-none'].asClass}
        >
          {
            Object.keys(subjects || {} as Record<string, string>).map((s, i) => (
              <Link
                key={s}
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', `${i}`)
                }}
                className={['button-primary px-6 w-max'].asClass}
                to={`/tests/svk/${s}`}
              >
                {subjects?.[s]}
              </Link>
            ))}
        </div>
        <div
          ref={(e) => {
            if (!e) return
            setTestOffset(e.clientHeight)
            if (!yearHidden && !twoShown) {
              e.classList.add("in")
              e.classList.remove("out")
            } else {
              !e.classList.contains("out") && e.classList.add("out")
              e.classList.contains("in") && e.classList.remove("in")
            }
          }}
          className={['grid grid-cols-2 gap-4 items-center relative pb-8 animate-subj', (!subject || year) && '!pointer-events-none'].asClass}
          style={{ top: -yearsOffset }}
        >
          {
            shortsAvailableForSubject && (
              <Link
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', '0')
                }}
                className="button-primary px-6 py-3 text-xl w-full col-span-2"
                to={`/shorts/${subject}`}
              >
                Shorts
              </Link>
            )
          }
          {[...(years || [])].map((s, i) => (
              <Link
                key={s}
                ref={(e) => {
                  if (!e) return
                  e.style.setProperty('--subj-index', `${Math.floor(i / 2) + (shortsAvailableForSubject ? 1 : 0)}`)
                }}
                className="button-primary px-6 w-max"
                to={`/tests/svk/${subject}/${s}`}
              >
                {s}
              </Link>
            ))}
        </div>
        <div
          ref={(e) => {
            if (!e) return
            if (!testHidden && !twoShown) {
              e.classList.remove("out")
              e.classList.add("in")
              e.style.opacity = "1";
            } else {
              !e.classList.contains("out") && e.classList.add("out")
              e.classList.contains("in") && e.classList.remove("in")
            }
          }}
          className={['grid grid-cols-2 gap-4 items-center relative pb-8 animate-subj', (!subject || !year) && '!pointer-events-none'].asClass}
          style={{ top: -yearsOffset - testOffset, opacity: 0 }}
        >
          <span
            ref={(e) => {
              if (!e) return
              e.style.setProperty('--subj-index', '0')
            }}
            className="text-[3.125rem] text-jajko font-bold text-center col-span-2 mb-10"
          >
            {lastTestData?.year}
          </span>
          <span
            ref={(e) => {
              if (!e) return
              e.style.setProperty('--subj-index', '1')
            }}
            className="bg-yellow-200 text-kura bg-jajko font-medium select-none w-full text-center px-6 py-2 rounded-lg"
          >
            {answers?.length} otázok
          </span>
          <span
            ref={(e) => {
              if (!e) return
              e.style.setProperty('--subj-index', '1')
            }}
            className="bg-yellow-200 text-kura bg-jajko font-medium select-none w-full text-center px-6 py-2 rounded-lg"
          >
            {lastTestData?.duration} minút
          </span>
          <div
            ref={(e) => {
              if (!e) return
              e.style.setProperty('--subj-index', '2')
            }}
            className="col-span-2"
          >
            <Link className="button-primary text-2xl py-3 font-bold bg-kura text-yellow-300 px-6 w-full" to="#" onClick={start}>
              ZAČAŤ TEST
            </Link>
            {user === null && (
              <div className="flex flex-col gap-2 mt-6 items-center">
              <span className='text-kura text-sm text-center font-semibold'>
                Pre ukladanie výsledkov testov a štatistiky sa prihláste.
              </span>
              <button className="button-primary text-2xl px-4 w-max py-1" onClick={start}>
                Prihlásiť sa
              </button>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TestPicker
